import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        public httpClient: HttpClient,
        public loadingController: LoadingController
    ) { }

    // local
    // private mobProApi = 'http://localhost:61279/api/';

    // test/dev
    // private mobProApi = 'https://testapi.office-mobile.com.kz/api/';

    // prod
    private mobProApi = 'https://api.mobile.com.kz/api/';


    signUp(data: any) {
        return this.post('Customers/SignUp', data);
    }

    signIn(data: any) {
        return this.post('Customers/SignIn', data);
    }

    customerActivate(data: any) {
        return this.post('Customers/Activate', data);
    }

    removeFlowType() {
        return this.post('Customers/Delete/Flow', []);
    }

    payAll(paymentId: any) {
        return this.post(`Payment/${paymentId}/Full/Subscription`, null);
    }

    addExchange(data: any) {
        return this.post('Certificates/fullexchange', data);
    }

    customerRequestResetPassword(data: any) {
        return this.post('Customers/RequestResetPassword', data);
    }

    customerResetPassword(data: any) {
        return this.post('Customers/ResetPassword', data);
    }

    getCustomerDevices() {
        return this.get('Customers/Devices');
    }

    getAccount() {
        return this.get('Customer');
    }

    getPackages() {
        return this.get('Packages');
    }

    getReceipt(data) {
        return this.post(`Payment/Receipt`, data);
    }

    getPhotosList(shopId) {
        return this.get(`Certificate/ShopId/${shopId}/Available/Photos`);
    }

    getCustomerDevice(id: number) {
        return this.get('Customers/Devices/' + id);
    }

    getActiveDevice() {
        return this.get('Customers/Device/Active');
    }

    getCertificatePrice(data: any) {
        return this.post('Customers/Device/CertificatePrice', data);
    }

    createDeviceWithTest(data: any) {
        return this.post('Customers/Device/Crete/WithTest', data);
    }

    createCertificateSale(data: any) {
        return this.post('Certificates/sale', data);
    }

    checkCertificate(certSeries: any, certNumber: number, shopId: number) {
        return this.post(`Certificates/get/${certSeries}/${certNumber}/${shopId}`, []);
    }

    createPayment(data: any) {
        return this.post('Payment/Create', data);
    }

    getPayments() {
        return this.get('Payments');
    }
    getPaymentsByDeviceId(deviceId: any) {
        return this.get(`Payments/Device/${deviceId}`);
    }

    getPaymentDetails(id: number) {
        return this.get('Payment/' + id);
    }

    getRecurringPayment(data: any) {
        return this.post('Recurring/Payment/Details', data);
    }

    deleteCustomerDevice(id: number) {
        return this.post('Customers/Devices/Delete/' + id, []);
    }

    addDevice(data: any) {
        return this.post('Customers/Devices/Add', data);
    }

    addDeviceTesting(id: number, data: any) {
        return this.post('Customers/Devices/AddTesting/' + id, data);
    }

    activateDevice(customerDeviceId: number) {
        return this.post(`/Customers/Devices/${customerDeviceId}/Activate`, []);
    }

    sendContactUsLetter(data: any) {
        return this.post('Customers/SendMail', data);
    }

    sendDeviceToAdd(data: any) {
        return this.post('send/email', data);
    }

    getDeviceTestings() {
        return this.get('Customers/Devices/GetTestings/');
    }

    getClarificationById(id: number) {
        return this.get(`Certificate/ExchangeId/${id}/Communication`);
    }

    getSetShopIsAvailable(id: number) {
        return this.get(`Certificate/${id}/SetShop/IsAvailable`);
    }

    sendClarification(data: any) {
        return this.post('Certificate/Send/Clarifications', data);
    }

    getNotifications(CustomerID: number) {
        return this.get(`messages/get/${CustomerID}/userType/1`);
    }

    getDeviceTesting(deviceId: number) {
        return this.get(`Customers/Device/${deviceId}/Testings`);
    }

    deleteDeviceTesting(id: number) {
        return this.post('Customers/Devices/DeleteTesting/' + id, []);
    }

    sendCustomerIIN(data: any) {
        return this.post('Customers/Add/CustomerIIN', data);
    }

    sendStatus(data: any) {
        return this.post('Certificate/Approval/SetStatus', data);
    }

    getCities(Series: string, shopId: string) {
        return this.get(`Cities/Series/${Series}/ShopId/${shopId}`);
    }

    getShops(data: any) {
        return this.post('Certificate/Gift/Shops/Search', data);
    }

    sendPreApproved(data: any) {
        return this.post('Certificate/Approval/SetShop', data);
    }

    sendInstallments(data: any) {
        return this.post('Payment/Price/Calculation', data);
    }

    cancelBuyCertificate(certificateId: number) {
        return this.post(`Certificate/${certificateId}/Payment/Reject`, []);
    }

    checkRefundable(id: number) {
        return this.post(`Payment/${id}/Refundable`, []);
    }

    readNotification(id: number) {
        return this.post(`message/${id}/read`, []);
    }

    verifyToken(data) {
        return this.post(`Click/Payment/Create`, data);
    }

    getLangs() {
        return this.get('languages');
    }

    updateTesting(data: any) {
        return this.put('Customers/Devices/UpdateTesting', data);
    }

    getCardToken(data: any, sha1: string, headers: any = {}) {
        const authToken = localStorage.getItem('user.AuthToken') || '';
        if (authToken) {
            // headers['X-Access-Token'] = authToken;
            // headers['Auth'] = `20509:${sha1}:${Date.now()}`;
            // headers['Auth'] = `20509:ec60ddb12f2f0aafeacac6632d1f733bacda5dc0:1626166017`;
            headers['Accept'] = `application/json`;
            headers['Content-Type'] = `application/json`;
        }
        return new Promise((resolve, reject) => {
            this.loadingController.create({
                message: 'Загрузка...'
            }).then((res) => {
                res.present();
                this.httpClient.post('https://api.click.uz/v2/merchant/card_token/request', data, {headers})
                  .subscribe((resp: HttpResponse<any>) => {
                      res.dismiss();
                      resolve(resp);
                  }, (e) => {
                      res.dismiss();
                      reject(e);
                  });
            });
        });
    }

    put(path: string, data: any, headers: any = {}) {
        const authToken = localStorage.getItem('user.AuthToken') || '';
        if (authToken) {
            headers['X-Access-Token'] = authToken;
        }
        return new Promise((resolve, reject) => {
            this.loadingController.create({
                message: 'Загрузка...'
            }).then((res) => {
                res.present();
                this.httpClient.put(this.mobProApi + path, data, {headers})
                    .subscribe((resp: HttpResponse<any>) => {
                        res.dismiss();
                        resolve(resp);
                    }, (e) => {
                        res.dismiss();
                        reject(e);
                    });
            });
        });
    }

    post(path: string, data: any, headers: any = {}) {
        const authToken = localStorage.getItem('user.AuthToken') || '';
        if (authToken) {
            headers['X-Access-Token'] = authToken;
        }
        return new Promise((resolve, reject) => {
            this.loadingController.create({
                message: 'Загрузка...'
            }).then((res) => {
                res.present();
                this.httpClient.post(this.mobProApi + path, data, {headers})
                    .subscribe((resp: HttpResponse<any>) => {
                        res.dismiss();
                        resolve(resp);
                    }, (e) => {
                        res.dismiss();
                        reject(e);
                    });
            });
        });
    }

    get(path: string, headers: any = {}) {
        const authToken = localStorage.getItem('user.AuthToken') || '';
        if (authToken) {
            headers['X-Access-Token'] = authToken;
        }
        return new Promise((resolve, reject) => {
           this.loadingController.create({
                message: 'Загрузка...'
            }).then((res) => {
                res.present();
                this.httpClient.get(this.mobProApi + path, {headers})
                    .subscribe((resp: HttpResponse<any>) => {
                        resolve(resp);
                        res.dismiss();
                    }, (e) => {
                        reject(e);
                        res.dismiss();
                    });
            });
        });
    }

    getDictDevices() {
        return this.get('DictDevices');
    }
}
