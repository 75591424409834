import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {CanActivateLogged} from './can-activate-logged';
import {CanActivateGuest} from './can-activate-guest';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/tabs/home', pathMatch: 'full',
    canActivate: [CanActivateGuest]
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'in-progress',
    loadChildren: () => import('./pages/in-progress/in-progress.module').then( m => m.InProgressPageModule)
  },
  {
    path: 'card/:id',
    loadChildren: () => import('./pages/card-detail/card-detail.module').then( m => m.CardDetailPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'devices',
    loadChildren: () => import('./pages/devices/devices.module').then( m => m.DevicesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'devices-report/:from',
    loadChildren: () => import('./pages/devices-report/devices-report.module').then( m => m.DevicesReportPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'devices-report/:from/:id',
    loadChildren: () => import('./pages/devices-report/devices-report.module').then( m => m.DevicesReportPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'device-add',
    loadChildren: () => import('./pages/device-add/device-add.module').then( m => m.DeviceAddPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'device-view/:id',
    loadChildren: () => import('./pages/device-view/device-view.module').then( m => m.DeviceViewPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'device-report/:id',
    loadChildren: () => import('./pages/device-report/device-report.module').then( m => m.DeviceReportPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'my-services',
    loadChildren: () => import('./pages/my-services/my-services.module').then( m => m.MyServicesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'buy-service/:customer_device_id',
    loadChildren: () => import('./pages/buy-service/buy-service.module').then( m => m.BuyServicePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'buy-service',
    loadChildren: () => import('./pages/buy-service/buy-service.module').then( m => m.BuyServicePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'exchange',
    loadChildren: () => import('./pages/exchange/exchange.module').then( m => m.ExchangePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'transfer-funds',
    loadChildren: () => import('./pages/transfer-funds/transfer-funds.module').then( m => m.TransferFundsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'confirm-transfer',
    loadChildren: () => import('./pages/confirm-transfer/confirm-transfer.module').then( m => m.ConfirmTransferPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'complete-modal',
    loadChildren: () => import('./pages/complete-modal/complete-modal.module').then( m => m.CompleteModalPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot/:type',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'confirm-code/:type',
    loadChildren: () => import('./pages/confirm-code/confirm-code.module').then( m => m.ConfirmCodePageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'incomes-insights',
    loadChildren: () => import('./pages/incomes-insights/incomes-insights.module').then( m => m.IncomesInsightsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'credit-statement',
    loadChildren: () => import('./pages/credit-statement/credit-statement.module').then( m => m.CreditStatementPageModule)
  },
  {
    path: 'screen-sensor',
    loadChildren: () => import('./tests/screen-sensor/screen-sensor.module').then( m => m.ScreenSensorPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sound-speaker-loud',
    loadChildren: () => import('./tests/sound-speaker-loud/sound-speaker-loud.module').then( m => m.SoundSpeakerLoudPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sound-speaker',
    loadChildren: () => import('./tests/sound-speaker/sound-speaker.module').then( m => m.SoundSpeakerPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sound-mic',
    loadChildren: () => import('./tests/sound-mic/sound-mic.module').then( m => m.SoundMicPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sound-headphones',
    loadChildren: () => import('./tests/sound-headphones/sound-headphones.module').then( m => m.SoundHeadphonesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sensor-accelerometer',
    loadChildren: () => import('./tests/sensor-accelerometer/sensor-accelerometer.module').then( m => m.SensorAccelerometerPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sensor-compass',
    loadChildren: () => import('./tests/sensor-compass/sensor-compass.module').then( m => m.SensorCompassPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'sensor-gyroscope',
    loadChildren: () => import('./tests/sensor-gyroscope/sensor-gyroscope.module').then( m => m.SensorGyroscopePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'connection-wifi',
    loadChildren: () => import('./tests/connection-wifi/connection-wifi.module').then( m => m.ConnectionWifiPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'connection-bluetooth',
    loadChildren: () => import('./tests/connection-bluetooth/connection-bluetooth.module').then( m => m.ConnectionBluetoothPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'connection-cellular',
    loadChildren: () => import('./tests/connection-cellular/connection-cellular.module').then( m => m.ConnectionCellularPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'connection-gps',
    loadChildren: () => import('./tests/connection-gps/connection-gps.module').then( m => m.ConnectionGpsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-light-sensor',
    loadChildren: () => import('./tests/hardware-light-sensor/hardware-light-sensor.module').then( m => m.HardwareLightSensorPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-charger',
    loadChildren: () => import('./tests/hardware-charger/hardware-charger.module').then( m => m.HardwareChargerPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-buttons',
    loadChildren: () => import('./tests/hardware-buttons/hardware-buttons.module').then( m => m.HardwareButtonsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-vibration',
    loadChildren: () => import('./tests/hardware-vibration/hardware-vibration.module').then( m => m.HardwareVibrationPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-proximity',
    loadChildren: () => import('./tests/hardware-proximity/hardware-proximity.module').then( m => m.HardwareProximityPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'hardware-fingerprint',
    loadChildren: () => import('./tests/hardware-fingerprint/hardware-fingerprint.module').then( m => m.HardwareFingerprintPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'camera-front',
    loadChildren: () => import('./tests/camera-front/camera-front.module').then( m => m.CameraFrontPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'camera-rear',
    loadChildren: () => import('./tests/camera-rear/camera-rear.module').then( m => m.CameraRearPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'camera-flash',
    loadChildren: () => import('./tests/camera-flash/camera-flash.module').then( m => m.CameraFlashPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'price-buy-service',
    loadChildren: () => import('./pages/price-buy-service/price-buy-service.module').then( m => m.PriceBuyServicePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'price-buy-service/:id',
    loadChildren: () => import('./pages/price-buy-service/price-buy-service.module').then( m => m.PriceBuyServicePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'payment-details/:id',
    loadChildren: () => import('./pages/payment-details/payment-details.module').then( m => m.PaymentDetailsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'find-imei',
    loadChildren: () => import('./pages/find-imei/find-imei.module').then(m => m.FindImeiPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'information-for-test',
    loadChildren: () => import('./pages/information-for-test/information-for-test.module').then(m => m.InformationForTestPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'certificate',
    loadChildren: () => import('./pages/certificate/certificate.module').then(m => m.CertificatePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'buy-service-uz',
    loadChildren: () => import('./pages/buy-service-uz/buy-service-uz.module').then(m => m.BuyServiceUzPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'certificates-modal/:id',
    loadChildren: () => import('./pages/certificates-modal/certificates-modal.module').then(m => m.CertificatesModalPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'kts-devices',
    loadChildren: () => import('./pages/kts-devices/kts-devices.module').then(m => m.KtsDevicesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'kts-exchange-devices',
    loadChildren: () => import('./pages/kts-exchange-devices/kts-exchange-devices.module').then(m => m.KtsExchangeDevicesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'exchange-first',
    loadChildren: () => import('./pages/exchange-first/exchange-first.module').then(m => m.ExchangeFirstPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'exchange-photos/:id',
    loadChildren: () => import('./pages/exchange-photos/exchange-photos.module').then(m => m.ExchangePhotosPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'exchange-final/:id',
    loadChildren: () => import('./pages/exchange-final/exchange-final.module').then(m => m.ExchangeFinalPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'kts-packages',
    loadChildren: () => import('./pages/kts-packages/kts-packages.module').then(m => m.KtsPackagesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'buy-certificate-login',
    loadChildren: () => import('./pages/buy-certificate-login/buy-certificate-login.module').then(m => m.BuyCertificateLoginPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'notifications-details/:id',
    loadChildren: () => import('./pages/notifications-details/notifications-details.module').then(m => m.NotificationsDetailsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'kts-payment-devices',
    loadChildren: () => import('./pages/kts-payment-devices/kts-payment-devices.module').then(m => m.KtsPaymentDevicesPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'payment-schedule/:id',
    loadChildren: () => import('./pages/payment-schedule/payment-schedule.module').then(m => m.PaymentSchedulePageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'schedule-details/:id',
    loadChildren: () => import('./pages/schedule-details/schedule-details.module').then(m => m.ScheduleDetailsPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'refund/:id',
    loadChildren: () => import('./pages/refund/refund.module').then(m => m.RefundPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'clarification/:id',
    loadChildren: () => import('./pages/clarification/clarification.module').then(m => m.ClarificationPageModule),
    canActivate: [CanActivateLogged]
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'pre-approved/:id',
    loadChildren: () => import('./pages/pre-approved/pre-approved.module').then(m => m.PreApprovedPageModule)
  },
  {
    path: 'pre-approved-cities/:Series',
    loadChildren: () => import('./pages/pre-approved-cities/pre-approved-cities.module').then(m => m.PreApprovedCitiesPageModule)
  },
  {
    path: 'pre-approved-shops',
    loadChildren: () => import('./pages/pre-approved-shops/pre-approved-shops.module').then(m => m.PreApprovedShopsPageModule)
  },
  {
    path: 'refund-devices',
    loadChildren: () => import('./pages/refund-devices/refund-devices.module').then(m => m.RefundDevicesPageModule)
  },
  {
    path: 'rejection-devices',
    loadChildren: () => import('./pages/rejection-devices/rejection-devices.module').then(m => m.RejectionDevicesPageModule)
  },
  {
    path: 'approved-devices',
    loadChildren: () => import('./pages/approved-devices/approved-devices.module').then(m => m.ApprovedDevicesPageModule)
  },
  {
    path: 'return-certificate',
    loadChildren: () => import('./pages/return-certificate/return-certificate.module').then(m => m.ReturnCertificatePageModule)
  },
  {
    path: 'personal-terms',
    loadChildren: () => import('./pages/personal-terms/personal-terms.module').then(m => m.PersonalTermsPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsPageModule)
  },




























];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
