import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {CanActivate, RouteReuseStrategy} from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { Device } from '@ionic-native/device/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { Gyroscope } from '@ionic-native/gyroscope/ngx';
import { DeviceMotion } from '@ionic-native/device-motion/ngx';
import { DeviceOrientation } from '@ionic-native/device-orientation/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { Hotspot } from '@ionic-native/hotspot/ngx';
import { Sim } from '@ionic-native/sim/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Flashlight } from '@ionic-native/flashlight/ngx';
import { Sensors } from '@ionic-native/sensors/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {AppState} from './app.global';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import * as Sentry from 'sentry-cordova';
import {SentryIonicErrorHandler} from '../sentryHandler';
import {AlertComponent} from './components/alert/alert.component';
import {CanActivateLogged} from './can-activate-logged';
import {CanActivateGuest} from './can-activate-guest';
import {GoogleAnalytics} from '@ionic-native/google-analytics/ngx';
import { TextMaskModule } from 'angular2-text-mask';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

Sentry.init({dsn: 'https://90439caf3e97402e8ae78c9a3923ab3d@o438828.ingest.sentry.io/5406318'});

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    TextMaskModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    GoogleAnalytics,
    Device,
    Camera,
    CameraPreview,
    TextToSpeech,
    SpeechRecognition,
    Gyroscope,
    DeviceMotion,
    DeviceOrientation,
    BluetoothLE,
    BLE,
    Hotspot,
    Sim,
    LocationAccuracy,
    Geolocation,
    BatteryStatus,
    Vibration,
    FingerprintAIO,
    Flashlight,
    Sensors,
    AndroidPermissions,
    InAppBrowser,
    ScreenOrientation,
    AppState,
    Deeplinks,
    AlertComponent,
    CanActivateLogged,
    CanActivateGuest,
    TranslateModule,
    CallNumber,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: ErrorHandler, useClass: SentryIonicErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
