import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'alert-component',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.css'],
})
export class AlertComponent {

    constructor(public alertController: AlertController,
                private translateService: TranslateService) {}

    async presentAlert(message?, values?) {
        const alert = await this.alertController.create({
            cssClass: 'present-alert-class',
            header: '',
            subHeader: '',
            message: '',
            buttons: ['OK']
        });

        this.translateService.get(`ALERT.${message}`).subscribe(t => {
            alert.message = t;
            if (values) {
                alert.message += values;
            }
        });
        await alert.present();
    }

    async simpleAlert(message) {
        const alert = await this.alertController.create({
            cssClass: 'simple-alert-class',
            header: '',
            subHeader: '',
            message,
            buttons: ['OK']
        });
        await alert.present();
    }

    async presentAlertMultipleButtons() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            subHeader: 'Subtitle',
            message: 'This is an alert message.',
            buttons: ['Cancel', 'Open Modal', 'Delete']
        });

        await alert.present();
    }

    async presentAlertConfirm(isTranslate, message, okCallback?, cancleCallback?) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: '',
            message,
            buttons: [
                {
                    text: this.translateService.instant(`ALERT.CANCEL`),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        if (cancleCallback) {
                            cancleCallback();
                        }
                    }
                }, {
                    text: this.translateService.instant(`ALERT.OK`),
                    handler: () => {
                        if (okCallback) {
                            okCallback();
                        }
                    }
                }
            ]
        });

        if (isTranslate) {
            this.translateService.get(`ALERT.${message}`).subscribe(t => {
                alert.message = t;
            });
        }

        await alert.present();
    }

    async simpleAlertWithCallback(message, okCallback?) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: '',
            message,
            buttons: [
                 {
                    text: this.translateService.instant(`ALERT.OK`),
                    handler: () => {
                        if (okCallback) {
                            okCallback();
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    async continueTestAlert(message, cancleCallback?, okCallback?) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: '',
            message,
            buttons: [
                {
                    text: this.translateService.instant(`ALERT.NO`),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        if (cancleCallback) {
                            cancleCallback();
                        }
                    }
                }, {
                    text: this.translateService.instant(`ALERT.YES`),
                    handler: () => {
                        if (okCallback) {
                            okCallback();
                        }
                    }
                }
            ]
        });
        this.translateService.get(`ALERT.${message}`).subscribe(t => {
            alert.message = t;
        });
        await alert.present();
    }

    async presentAlertPrompt() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Prompt!',
            inputs: [
                {
                    name: 'name1',
                    type: 'text',
                    placeholder: 'Placeholder 1'
                },
                {
                    name: 'name2',
                    type: 'text',
                    id: 'name2-id',
                    value: 'hello',
                    placeholder: 'Placeholder 2'
                },
                // multiline input.
                {
                    name: 'paragraph',
                    id: 'paragraph',
                    type: 'textarea',
                    placeholder: 'Placeholder 3'
                },
                {
                    name: 'name3',
                    value: 'http://ionicframework.com',
                    type: 'url',
                    placeholder: 'Favorite site ever'
                },
                // input date with min & max
                {
                    name: 'name4',
                    type: 'date',
                    min: '2017-03-01',
                    max: '2018-01-12'
                },
                // input date without min nor max
                {
                    name: 'name5',
                    type: 'date'
                },
                {
                    name: 'name6',
                    type: 'number',
                    min: -5,
                    max: 10
                },
                {
                    name: 'name7',
                    type: 'number'
                },
                {
                    name: 'name8',
                    type: 'password',
                    placeholder: 'Advanced Attributes',
                    cssClass: 'specialClass',
                    attributes: {
                        maxlength: 4,
                        inputmode: 'decimal'
                    }
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Ok',
                    handler: () => {
                        console.log('Confirm Ok');
                    }
                }
            ]
        });

        await alert.present();
    }

    async presentAlertRadio() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Radio',
            inputs: [
                {
                    name: 'radio1',
                    type: 'radio',
                    label: 'Radio 1',
                    value: 'value1',
                    checked: true
                },
                {
                    name: 'radio2',
                    type: 'radio',
                    label: 'Radio 2',
                    value: 'value2'
                },
                {
                    name: 'radio3',
                    type: 'radio',
                    label: 'Radio 3',
                    value: 'value3'
                },
                {
                    name: 'radio4',
                    type: 'radio',
                    label: 'Radio 4',
                    value: 'value4'
                },
                {
                    name: 'radio5',
                    type: 'radio',
                    label: 'Radio 5',
                    value: 'value5'
                },
                {
                    name: 'radio6',
                    type: 'radio',
                    label: 'Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 ',
                    value: 'value6'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Ok',
                    handler: () => {
                        console.log('Confirm Ok');
                    }
                }
            ]
        });

        await alert.present();
    }

    async presentAlertCheckbox() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Checkbox',
            inputs: [
                {
                    name: 'checkbox1',
                    type: 'checkbox',
                    label: 'Checkbox 1',
                    value: 'value1',
                    checked: true
                },

                {
                    name: 'checkbox2',
                    type: 'checkbox',
                    label: 'Checkbox 2',
                    value: 'value2'
                },

                {
                    name: 'checkbox3',
                    type: 'checkbox',
                    label: 'Checkbox 3',
                    value: 'value3'
                },

                {
                    name: 'checkbox4',
                    type: 'checkbox',
                    label: 'Checkbox 4',
                    value: 'value4'
                },

                {
                    name: 'checkbox5',
                    type: 'checkbox',
                    label: 'Checkbox 5',
                    value: 'value5'
                },

                {
                    name: 'checkbox6',
                    type: 'checkbox',
                    label: 'Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6',
                    value: 'value6'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Ok',
                    handler: () => {
                        console.log('Confirm Ok');
                    }
                }
            ]
        });

        await alert.present();
    }
}
